<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
      <div class="card card-fullheight m-0 pb-2 w-100">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title mb-1 pb-1"><small>Equipe de </small> {{ team.nome }}</h5>
          <hr>

          <!-- Informações -->
          <div class="row mt-2">
            <div v-for="(employee, index) in employees" v-bind:key="index"
              class="col-md-4 text-left pb-3">
              <div class="font-weight-bold">
                {{ employee.nome }}
              </div>
              <small>{{ employee.cargo }}</small>
            </div>

            <!-- Vazio -->
            <div v-if="employees.length <= 0" class="col-12 text-left pb-3">
              <small>Sem funcionários registrados</small>
            </div>
          </div>

          <hr>

          <!-- Botões abaixo -->
          <div class="row">
            <div class="col-12 align-center">
                <a class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('TeamEdit', this.team)">Editar</a>

                <button class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onClose()">
                  <span >Fechar</span>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import TeamService from '@/services/TeamService'

export default {
  name: 'TeamDetailView',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      subsLoaded: false,
      employees: []
    }
  },
  beforeMount () {
    this.getEmployees()
  },
  mounted () {
  },
  methods: {
    onfocus () {
      // console.log('this.team: ', this.team)
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    getEmployees () {
      let _this = this

      return new Promise((resolve, reject) => {
        TeamService.get(this.team.id).then(res => {
          // _this.employees = res.data.data
          let team = res.data.data
          this.employees = team.funcionarios.map((x) => {
            return {
              id: x.funcionario.id,
              cargo: x.funcionario.id_cargo.nome,
              nome: x.funcionario.id_pessoa.nome
            }
          })
          _this.subsLoaded = true
        }).catch((err) => {
          // console.log(err)
          reject(err)
        })
      })
    }
  },
  computed: {
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
